<template>
  <span
    class="regular-12 badge badge-outline w-3rem"
    :style="`background: linear-gradient(90deg,rgba(180, 96, 220, 0.3) 0%,rgba(180, 96, 220, 0.3) ${Math.round(modelValue)}%,rgba(180, 96, 220, 0) ${Math.round(modelValue)}%,rgba(180, 96, 220, 0) 100%);`"
  >
    {{Math.round(modelValue)}}%
  </span>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    id: Number,
    itemType: String,
    modelValue: Number,
  },
};
</script>
